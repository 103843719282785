/* This SCSS file is responsible for all of the CSS on rmp pages.
 */
@import "globals";
@import "brand_colors";

$smart-import-attribute-column-color: #5ac0f1;
$smart-import-result-column-color: #b78eff;

@mixin rounded-border(
  $show-box-shadow: 1,
  $top-left-radius: $border-radius,
  $top-right-radius: $border-radius,
  $bottom-left-radius: $border-radius,
  $bottom-right-radius: $border-radius
) {
  @if $show-box-shadow == 1 {
    box-shadow: $box-shadow;
  }

  border-radius: $top-left-radius $top-right-radius $bottom-right-radius $bottom-left-radius;
}

.import-dashboard-page {
  @include rounded-border;
}

.import-dashboard-container {
  margin-top: 20px;
  margin-bottom: 5px;
}

.import-record-widget-btn {
  margin-top: 15px;
  margin-bottom: 113px;
}

.import-record-widget-title {
  font-size: calculateRem(20px);
  font-weight: bold;
  margin-top: 15px;
}

.import-record-widget-content {
  border: 2px solid $brand-mild-grey;
}

.import-record-widget {
  margin-bottom: 15px;
  padding-left: 22px;
  padding-right: 22px;
}

.import-record-widget-instructions-panel {
  background-color: #f6f6f6;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.import-record-file-upload-instructions-panel {
  min-width: 500px;
  min-height: 200px;

  > div {
    height: 100%;
  }
}

.import-record-widget-instructions {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: calculateRem(15px);
  color: black;
}

.import-record-widget-instructions-description {
  color: $brand-dark-grey;
}

.import-record-widget-instructions-description-option {
  margin-left: 20px;
}

.import-file-spacer,
.import-record-widget-instructions-spacer {
  flex-grow: 1;
}

.import-record-widget-links,
.import-record-widget-download-links {
  margin-bottom: 18px;
}

.import-project-widget-instructions-panel {
  padding-left: 0;
  display: contents;

  .import-record-widget-download-links {
    margin-bottom: 0;
  }
}

.import-record-widget-disabled-link,
.import-record-widget-download-link {
  display: flex;
}

.import-screen-footer {
  border-top: 1px solid $brand-mild-grey;
  margin-top: 3px;
  padding: 10px 30px 10px 30px !important;
}

.import-smart-upload-screen-footer {
  border-top: 1px solid $brand-mild-grey;
  padding: 10px 30px 10px 30px;
}

.import-screen-footer button {
  width: 140px;
}

.import_row_div {
  margin-bottom: 10px;
}

.import-file-upload-div {
  align-content: space-between;
  height: 430px;
  width: 100%; // For smaller screens
}

.import-file-upload-div-padding {
  padding-bottom: 50px;
  padding-left: 15px;
}

.import-footer-btn {
  float: right;
  margin-left: 5px;
}

.import-footer-btn-fixed {
  width: 150px;
}

.import-file-step-exit-div {
  display: flex;
  align-items: center;
}

.import-file-step-template-link-container {
  margin-left: 20px;
  font-weight: 600;
}

.import-file-attach-button {
  height: initial;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 6px;

  .svg-inline--fa {
    margin: 0 5px;
  }
}

.import-support-file-uploader {
  margin-top: 20px;
}

.import-file-selector {
  font-size: calculateRem(16px);
}

.import-file-selector input {
  width: 300px;
  border: 1px solid;
  height: 35px;
  font-size: calculateRem(15px);
}

.import-file-selector div {
  display: inline-block;
  margin-left: 10px;
}

.import-file-selector .svg-inline--fa {
  margin-left: -30px;
  background: $brand-white;
  padding: 6px;
  cursor: pointer;
}

.import-file-selector-progress {
  height: initial;
  margin: 3px 15px 0;
  padding-left: 0;
  padding-right: 0;
}

.import-data-review-div {
  color: black;
  font-size: calculateRem(17px);
  margin-top: 10px;
  min-height: 125px;
}

.import-paper-div {
  min-height: 125px;
  color: black;
  width: 100%;
}

@media (min-width: 576px) {
  .import-paper-div {
    max-width: 900px;
  }

  .import-error-bar {
    max-width: 466px;
  }

  .import-results-summary-table-div {
    max-width: 466px;
  }

  .import-data-review-table-div {
    max-width: 466px;
  }

  .import-dependencies-table-div {
    max-width: 466px;
  }
}

@media (min-width: 768px) {
  .import-paper-div {
    max-width: 900px;
  }

  .import-error-bar {
    max-width: 658px;
  }

  .import-results-summary-table-div {
    max-width: 658px;
  }

  .import-data-review-table-div {
    max-width: 658px;
  }

  .import-dependencies-table-div {
    max-width: 658px;
  }
}

@media (min-width: 992px) {
  .import-paper-div {
    max-width: 960px;
  }

  .import-error-bar {
    max-width: 882px;
  }

  .import-results-summary-table-div {
    max-width: 882px;
  }

  .import-data-review-table-div {
    max-width: 882px;
  }

  .import-dependencies-table-div {
    max-width: 882px;
  }
}

@media (min-width: 1200px) {
  .import-paper-div {
    max-width: 1140px;
  }

  .import-error-bar {
    max-width: 1090px;
  }

  .import-results-summary-table-div {
    max-width: 1090px;
  }

  .import-data-review-table-div {
    max-width: 1090px;
  }

  .import-dependencies-table-div {
    max-width: 1090px;
  }
}

.import-paper-div > div:last-child {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 400px;
  height: 60vh;
}

.import-data-review-widget-title {
  padding-top: 32px;
  padding-bottom: 10px;
}

.import-data-review-widget-summary {
  font-weight: normal;
  font-size: calculateRem(16px);
  margin-left: -16px;
  margin-top: -16px;
}

.import-data-review-title {
  font-style: italic;
  font-family: "Roboto", sans-serif;
}

.import-data-review-progress {
  height: initial;
  width: 550px;
}

.import-result-pc-link-icon {
  cursor: pointer;
}

.card-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 10px 15px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.card-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  padding: 10px 15px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.card-error-body {
  font-size: calculateRem(13px);
  font-weight: normal;
  color: red;
  margin-top: calculateRem(7px);
  margin-left: calculateRem(30px);
}

.card-error-warning {
  font-size: calculateRem(13px);
  font-weight: normal;
  color: black;
  margin-top: calculateRem(7px);
  margin-left: calculateRem(30px);
}

.import-data-review-widget-summary-div {
  padding-top: 20px;
  padding-bottom: 40px;
}

.import-error-bar {
  margin: 10px;
  max-width: none;
}

.import-data-review-table-div {
  margin-top: 12px;
}

.import-dependencies-table-div {
  margin-top: 20px;
}

.import-results-summary-title {
  font-weight: bold;
  font-size: calculateRem(15px);
  padding: 0 15px;
}

.import-results-summary-message,
.import-results-summary-info {
  font-size: calculateRem(15px);
  padding: 15px;

  a {
    margin: 0 5px;
  }
}

.import-results-success-message {
  border: 0.5px solid $brand-primary-color;
  border-radius: 4px;
}

.import-nav-bar-container {
  margin-top: 4px;
}

.import-nav-tabs-container {
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.import-nav-tabs {
  border-bottom: none;
}

.import-nav-tab {
  font-size: calculateRem(15px);
  padding: 15px;

  a {
    border: none !important;
  }

  a:hover {
    background-color: transparent;
  }
}

.import-nav-tab.active {
  @include rounded-border($bottom-left-radius: 0, $bottom-right-radius: 0);

  background-color: $brand-white;
  clip-path: polygon(-20px -20px, 130% -20px, 130% 95%, -20px 95%);
  margin-bottom: -1px;
  z-index: 2;
}

.import-nav-tab.active:first-child {
  clip-path: polygon(-20px -20px, 130% -20px, 130% 93%, -20px 98%);
}

.import-nav-inactive {
  color: $brand-dark-grey;
}

.import-project-page-toolbar {
  padding: 15px;
}

.import-exit-link {
  margin-top: 100px;
}

.import-project-container {
  @include rounded-border;

  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 15px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.import-results-review-container {
  @include rounded-border;
}

.import-project-popup-title {
  font-weight: bolder;
  font-size: calculateRem(16px);
  padding-bottom: 10px;
  margin-top: -5px;
}

.import-project-popup-container {
  margin-left: 15px;
  margin-right: 15px;
}

.continuous-data-measurements-table {
  table td {
    border: 1px solid #dee2e6;
  }
}

.continuous-data-attributes-table {
  table td {
    border-top: none;
    border-bottom: 1px solid #dee2e6;
    padding-left: 25px;
  }
}

.import-project-record-widgets-container {
  width: 100%;

  th {
    padding-left: 15px;
    padding-bottom: 10px;
  }

  td {
    padding: 15px;
  }

  th:first-child,
  td:first-child {
    padding-left: 30px;
  }

  th:last-child,
  td:last-child {
    padding-right: 30px;
  }
}

.import-project-record-widgets-row {
  border-bottom: 1px solid #ededed;
}

.import-project-record-widgets-row.no-borders {
  border-bottom: none !important;
}

.import-project-record-widgets-cell {
  vertical-align: top;
}

.import-project-record-widgets-header-row {
  color: darkgray;
  font-size: calculateRem(12px);
}

.import-project-record-description-column {
  width: 30%;
}

.import-project-record-details-column {
  width: 20%;
}

.import-project-record-instructions-column {
  width: 40%;
}

.import-project-record-button-column {
  width: 10%;
}

.import-project-widget-title {
  font-size: calculateRem(14px);
  font-weight: bold;
}

#importDataReviewTable tbody tr.selected {
  background-color: $brand-white;
  font-style: normal;
}

#importDataReviewTable tbody tr {
  background-color: $brand-light-grey;
  font-style: italic;
  font-size: calculateRem(12px);
}

#importResultsSummaryTable tbody tr {
  background-color: $brand-white;
  font-style: normal;
  font-size: calculateRem(12px);
}

#importResultsSummaryTable tbody tr td {
  background-color: #f6f6f6;
}

.import-project-widget-btn {
  float: right;
  margin-top: 0;
  min-width: 120px;
}

.import-project-widget-info {
  height: 35px;
  display: flex;
  padding-top: 10px;
  font-style: italic;
  font-size: calculateRem(13px);
}

.import-project-widget-subscript {
  margin-left: 13px;
  margin-top: -3px;
  font-size: calculateRem(12px);
  font-style: italic;
  color: $brand-dark-grey;
}

#dependencySelectionTable_filter,
#importDataReviewTable_filter {
  text-align: left;
  width: 50%;
}

#dependencySelectionTable_filter input,
#importDataReviewTable_filter input {
  width: 100%;
}

// Format the date picker in the spreadsheet
.rdg-editor-container input.react-datepicker-ignore-onclickoutside {
  width: 100%;
  height: 35px;
}

.rdg-editor-container {
  z-index: 10 !important;
}

.import-wizard-steps-container {
  @include rounded-border;

  padding: 0 0 0 0;
  margin-top: -2px;
}

.import-wizard-steps-container.first-wizard-step {
  @include rounded-border($show-box-shadow: 0, $top-left-radius: 0);
  flex-direction: column;
}

.import-wizard-steps-container.hidden {
  display: none;
}

.import-wizard-step-inner-container {
  padding: 20px 40px;
}

.import-results-div .import-wizard-step-inner-container {
  padding: 15px;
}

.import-wizard-div {
  z-index: 2000;
}

.import-data-panel {
  display: flex;
  flex-direction: column;
  min-width: 440px;
  height: 100%;
}

.import-data-panel.hidden {
  display: none;
}

.import-smart-upload-step {
  width: 100%;
  position: relative;
  min-height: 1px;
  min-width: 900px;
  padding-right: 0;
  padding-left: 0;
}

.import-data-extract-step {
  width: 100%;
  position: relative;
  min-height: 1px;
  min-width: 900px;
  padding-right: 0;
  padding-left: 0;
}

.import-document-div {
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-grow: 2;

  .import-data-ocr-text-div,
  .import-data-ocr-image-div {
    overflow: auto;
    width: 100%;
    height: 100%;
    flex-grow: 2;
    position: relative;
  }

  .import-data-ocr-pdf-div {
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-grow: 2;

    #importOCRPDFViewerDiv {
      overflow-x: auto;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .import-data-ocr-image-div.hidden,
  .import-data-ocr-pdf-div.hidden,
  .import-data-ocr-text-div.hidden {
    visibility: hidden;
  }
}

.import-data-ocr-image {
  border: 1px solid $brand-mild-grey;
  order: 1;
  width: 100%;
}

.import-data-ocr-page {
  font-size: calculateRem(10px);
  position: relative;
}

.import-data-ocr-nav-bar-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid;
  font-size: calculateRem(14px);
  margin-top: 5px;
}

.import-data-ocr-nav-bar {
  flex-grow: 2;
  white-space: nowrap;
}

.import-data-ocr-new-file-div {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  align-items: flex-end;
}

.import-data-ocr-new-file {
  white-space: nowrap;
  font-size: calculateRem(12px);
}

.import-data-ocr-control-bar {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $brand-light-grey;
  padding-left: 10px;
  font-size: calculateRem(14px);
}

.ocr-control-bar-view-controls {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;

  button {
    padding: 3px 10px 3px 10px !important;
    height: 30px;
    font-weight: normal;
  }

  #viewConvertedTextButton {
    margin-left: 0 !important;
    border-color: grey;
  }
}

.ocr-control-bar-pagination-controls,
.ocr-control-bar-zoom-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  height: 30px;
}

.ocr-control-bar-pagination-controls {
  flex-grow: 1;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: 15px;

  #ocrPageInput {
    width: 50px;
  }

  .ocr-control-bar-pagination-item {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.ocr-control-bar-zoom-controls {
  button {
    outline: 0 none !important;
    background-color: transparent;
    color: $hover-color;
    border: none;
    margin-left: 2px;
    margin-right: 2px;
    padding: 0 5px 2px 0 !important;
  }

  button:hover,
  button:active,
  button:focus,
  button:active:hover {
    background-color: transparent;
    color: #9f9f9f;
    box-shadow: none;
    border: none;
  }
}

.import-data-ocr-text-table,
.import-data-ocr-text-block {
  position: absolute;
  white-space: nowrap;
}

.import-result-column-dropdown {
  position: absolute;
  font-size: calculateRem(8px);
  width: 16px;
}

.import-result-column {
  .caret {
    vertical-align: top;
  }

  .btn .caret {
    margin-left: -4px;
  }

  .smart-import-column-button {
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-bottom: 0;

    &.dropdown-toggle::after {
      margin-left: -2px;
      vertical-align: top;
    }
  }

  .smart-import-column-button.hidden {
    display: none;
  }

  .dropdown-menu {
    margin-top: 0 !important;
  }
}

.import-result-column.attribute {
  border: solid 2px $smart-import-attribute-column-color;
  background-color: rgba(168, 227, 255, 0.1);
}

.import-result-column.result {
  border: solid 2px $smart-import-result-column-color;
  background-color: rgba(183, 142, 255, 0.1);
}

.import-result-column.none {
  border: solid 2px $brand-medium-grey;
}

.import-result-column.hidden {
  display: none;
}

.pc-link-icon {
  cursor: pointer;
  color: $brand-medium-grey;
}

@mixin import-paper-bottom-border {
  z-index: 5;
}

.import-paper-bottom-border-container {
  @include import-paper-bottom-border;

  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 5;
}

.import-paper-bottom-border-grey {
  @include import-paper-bottom-border;

  background-color: $high-confidence-color;
}

.import-paper-bottom-border-yellow {
  @include import-paper-bottom-border;

  background-color: $medium-confidence-color;
}

.import-paper-bottom-border-orange {
  @include import-paper-bottom-border;

  background-color: $low-confidence-color;
}

.gutter {
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
  border: grey 1px solid;
  background: grey;
}

.batch-data-editor-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 440px;

  .batch-data-editor-header-div {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;

    .batch-data-editor-dependency-div {
      font-size: calculateRem(18px);
      font-weight: bold;
      font-family: "Roboto", "Open Sans", sans-serif;
    }

    .batch-data-editor-help-div {
      flex-grow: 1;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.confidence-level-icon {
  color: $hover-color;
}

.confidence-level-tooltip {
  padding: 9px;

  .confidence-level-tooltip-header {
    font-size: calculateRem(18px);
    font-weight: bold;
  }

  .confidence-level-tooltip-message {
    font-size: calculateRem(12px);
    font-weight: normal;
    color: $brand-dark-grey;
  }

  .confidence-level {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 18px;

    .confidence-level-shape {
      -moz-border-radius: calc(#{$confidence-level-item-size} / 2);
      -webkit-border-radius: calc(#{$confidence-level-item-size} / 2);
      border-radius: calc(#{$confidence-level-item-size} / 2);
      width: $confidence-level-item-size;
      height: $confidence-level-item-size;
      border-style: none;
      margin-top: 2px;
    }

    .confidence-level-grey-shape {
      background-color: #ededed;
    }

    .confidence-level-yellow-shape {
      opacity: 0.7;
      background-color: #ffef47;
    }

    .confidence-level-orange-shape {
      background-color: #ffc49f;
    }

    .confidence-level-shape-text {
      margin-left: 12px;

      .confidence-level-shape-label {
        font-size: calculateRem(14px);
        font-weight: normal;
      }

      .confidence-level-shape-description {
        font-size: calculateRem(12px);
        font-weight: normal;
        color: $brand-dark-grey;
      }
    }
  }

  #confidenceGotItButton {
    margin-top: 20px;
    font-size: calculateRem(12px);
    padding: 5px 10px;
  }
}

.import-paper-batch-div {
  border-bottom: 1px solid $brand-mild-grey;
  display: flex;
  flex-wrap: wrap;
  font-size: calculateRem(14px);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  z-index: 2;

  .form-control {
    height: 24px !important;
    padding: 3px 22px 3px 6px;
    margin-right: 20px;
    width: 100px;
  }

  #batchIdEditor {
    width: 140px;
  }

  .date-editor .form-control {
    width: 115px;
  }
}

.import-paper-batch-measurements-div {
  font-size: calculateRem(14px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.import-paper-batch-attribute {
  white-space: nowrap;
  margin-bottom: 15px;
  z-index: 1;

  .highlights.input {
    max-width: 128px !important;
  }

  .error-icon {
    position: absolute;
    bottom: 2px;
    right: 24px;
    z-index: 3;
  }
}

.batch-data-zscore-link {
  margin-top: 5px;
}

.import-paper-batch-attribute.date-attribute {
  z-index: 2;
}

.import-paper-batch-attribute.select-attribute {
  .error-icon {
    position: absolute;
    bottom: 2px;
    right: 32px;
    z-index: 3;
  }
}

.attribute-measurements-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.attribute-measurements-row-header {
  padding: 10px 20px;
  border-bottom: 1px solid #ededed;
  font-size: calculateRem(12px);
  font-weight: normal;
  color: $brand-dark-grey;
}

.attribute-measurement-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.attribute-read-only-container {
  height: 24px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.attribute-read-only-container::-webkit-scrollbar {
  height: 8px !important;
}

.attribute-measurement.typeahead {
  align-self: flex-start !important;

  .form-control {
    height: 24px !important;
    padding: 3px 22px 3px 6px !important;
    width: 100% !important;
  }
}

.add-attribute-measurement,
.attribute-measurement {
  align-self: center;
  margin-bottom: 2px;
  margin-top: 2px;

  .form-control {
    height: 24px !important;
    width: 70px;
    padding: 3px 20px 3px 6px;
    margin-right: 5px;
  }

  .error-icon {
    position: absolute !important;
    bottom: 2px !important;
    right: 8px !important;
    z-index: 3;
  }

  .typeahead-import-error-div {
    .error-icon {
      position: absolute;
      bottom: 2px !important;
      right: 4px !important;
      z-index: 3;
    }
  }
}

.base-import-editor-div {
  position: relative;

  .tab-nav-bar-icon {
    margin-left: 0;
    margin-right: 0;
  }

  .close-button {
    position: absolute;
    color: $brand-dark-grey;
    background-color: $brand-white;
    top: -7px;
    right: 0;
    cursor: pointer;
    z-index: 3;
    font-size: calculateRem(12px);
    height: 14px;
  }

  input {
    background-color: transparent;
  }

  select {
    padding: 3px 6px 3px 6px !important;
  }

  .highlights {
    position: absolute;
    z-index: 1;
    height: 16px;
    overflow: hidden;
    background-color: transparent;
    color: transparent;
  }

  .highlights.input {
    left: 7px;
    right: 25px;
    top: 4px;
  }

  .highlights.select {
    left: 11px;
    top: 5px;
  }

  .highlights.typeahead {
    top: 4px;
    left: 7px;
    right: 22px;
    height: 16px;
    white-space: nowrap;
  }

  mark {
    color: transparent !important;
    background-color: transparent;
    padding: 0 !important;
    margin: 0 !important;
  }

  mark.grey {
    background-color: $high-confidence-color;
  }

  mark.yellow {
    background-color: $medium-confidence-color;
  }

  mark.orange {
    background-color: $low-confidence-color;
  }
}

.import-data-editor-input-div {
  z-index: 2;
  position: relative;

  .import-paper-editor {
    background-color: transparent;
  }

  .import-paper-editor.warning {
    border-color: $warning-color;

    input {
      border-color: $warning-color;
    }
  }

  .import-paper-editor.error {
    border-color: $error-color;

    input {
      border-color: $error-color;
    }
  }
}

.add-measurement-button {
  background-color: transparent !important;
}

.add-attribute-row,
.attribute-measurements-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #ededed;

  .delete-attribute-button {
    color: $hover-color;
    position: absolute;
    align-self: center;
    right: 6px;
    cursor: pointer;

    .dropdown-menu {
      min-width: initial;
      padding-top: 5px;
      padding-bottom: 5px;
      white-space: nowrap;

      .dropdown-item {
        padding: 5px 10px;

        span {
          margin-right: 10px;
        }
      }

      .dropdown-item:hover {
        background-color: $brand-light-grey;
      }
    }
  }
}

.add-attribute-button {
  font-size: calculateRem(12px);
  padding: 5px 10px;
}

.import-paper-file-uploader {
  margin-top: 10px;
  margin-bottom: 10px;

  .import-file-selector {
    font-size: calculateRem(12px);
  }

  input {
    height: 24px;
  }

  button {
    height: 24px;
    width: 32px;
  }

  span {
    font-size: calculateRem(12px);
  }

  .svg-inline--fa {
    top: -3px;
    left: -5px;
  }
}

.file-drag-and-drop-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}

.file-drag-and-drop-div.disabled {
  cursor: no-drop;
}

.file-drag-and-drop-div.hidden {
  display: none;
}

.highlight-for-dnd {
  border: 5px $brand-primary-color dashed;
  padding: 20px;
}

.file-drag-and-drop-label {
  font-size: calculateRem(32px);
  width: 250px;
  height: 100px;
}

.import-smart-select-div {
  padding: 20px 30px;

  .smart-select-dependency-selection-div {
    display: flex;
    flex-direction: column;
    align-items: start;
    white-space: nowrap;
  }

  .smart-select-dependency-field-with-tooltip-div {
    display: flex;
    margin-top: 8px;
  }

  .smart-select-document-selection-div {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: start;
    white-space: nowrap;

    #smartUploadDocumentLabel {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @mixin circle($radius, $backgroundColor, $width, $height) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    width: $width;
    height: $height;
    background-color: $backgroundColor;
  }

  .smart-select-content-selection-div {
    margin-top: 30px;
    margin-bottom: 2px;

    .material-attributes-div,
    .results-div {
      display: flex;
      margin-top: 5px;
      align-items: center;
    }

    .material-attributes-shape {
      @include circle(10px, $smart-import-attribute-column-color, 10px, 10px);
    }

    .results-attributes-shape {
      @include circle(10px, $smart-import-result-column-color, 10px, 10px);
    }

    .results-label,
    .material-attributes-label {
      margin-right: 7px;
      margin-left: 7px;
    }

    #selectedResultsColumns,
    #selectedAttributeColumns {
      color: $brand-dark-grey;
    }

    .advanced-options-div {
      margin-top: 20px;
      display: flex;

      .advanced-options-text-div {
        display: flex;
        flex-direction: column;
        margin-left: 7px;

        .advanced-options-info-div {
          margin-top: 5px;
          color: $brand-dark-grey;
        }
      }
    }
  }
}

.smart-upload-progress-popup {
  font-size: calculateRem(14px);

  .smart-upload-remaining-time-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.smart-upload-progress-status-panel {
  margin-top: 30px;

  .smart-upload-progress-status-row {
    margin-top: 20px;
  }

  .smart-upload-progress-status-label {
    margin-left: 10px;
  }

  .smart-upload-progress-status-label.idle {
    margin-left: 32px;
  }

  .smart-upload-progress-status-icon {
    width: 20px;
    height: 18px;
    color: #37ad8f;
  }

  .fa-pulse {
    color: $brand-dark-grey;
  }
}

.continuous-data-review-batch-panel {
  padding: 30px 10px;

  .batch-attribute-label {
    color: #666;
  }
}

.continuous-data-review-header-panel {
  padding: 10px 10px;

  .header-panel {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 0;

    .total-attributes {
      font-weight: bold;
      font-size: 14px;
      margin-right: 10px;
    }

    .total-ofl-attributes {
      font-size: 12px;
      margin-right: 10px;
    }

    .total-ofl-measurements {
      background: #fff9e6;
      border: 1px solid #ffc107;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 12px;
      padding: 3px;
    }
  }
}

.continuous-data-review-details-panel {
  margin-bottom: -3px;
  padding: 0 0 0 0;
  border-top: 1px solid $brand-mild-grey;

  .continuous-data-attributes-table-col {
    border-right: 1px solid $brand-mild-grey;
    border-left: 1px solid $brand-mild-grey;
    border-bottom: 1px solid $brand-mild-grey;

    .continuous-data-attributes-table {
      margin-left: -15px;
      margin-right: -15px;
      max-height: 400px;
      overflow-y: auto;

      table.dataTable {
        margin-top: 0 !important;
      }

      thead {
        display: none;
      }

      .selected {
        font-weight: 600;
        background: #ebf9f7;
      }
    }
  }

  .continuous-data-measurements-panel {
    .continuous-data-measurements-header {
      font-weight: 600;
      font-size: 18px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .continuous-data-measurements-table {
      height: 100%;
      border: 1px solid $brand-mild-grey;

      .table th {
        border-top: none;
      }

      .table tr.warning {
        background: #fff9e6;
      }

      .dts_label {
        display: none !important;
      }
    }
  }

  .continuous-data-information-panel {
    margin-top: 60px;

    .continuous-attribute-info-field {
      margin-bottom: 20px;
    }
  }
}

.continuous-data-attribute-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.continuous-measurements-ofl-div {
  background: #fff9e6;
  border: 1px solid #ffc107;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 3px;
  font-size: 12px;
  margin-left: 10px;
}

.import-type-selection-div {
  display: flex;
}

.import-template-popup-container {
  z-index: 2201 !important;
}

.import-template-popup {
  max-width: 800px;
  min-width: 475px;
}

.import-data-type-card {
  padding: 5px;
  margin-top: 5px;
  border: solid 1px lightgray;
  border-radius: 4px;
  font-size: calculateEm(12px);
}
