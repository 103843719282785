.project-risk-assessment {
  .view-attribute {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .risk-assessment-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #434952;
  }

  .col-form-label {
    font-weight: bold;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  .process-risk-assessment {
    margin-top: 1rem;
  }
}

.risk-assessment-type-subheader,
#rMPComboBoxLabel {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #000 !important;
}

.risk-assessment-type-subheader {
  margin-bottom: 8px !important;
}

#riskAssessmentTypeProductLabel,
#riskAssessmentMethodsLabel {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #434952;
}

#riskAssessmentMethods {
  margin-bottom: 12px;
  margin-top: 12px;

  .line-separator {
    margin-bottom: 5px;
  }
}

#riskAssessmentTypeProductLabel2 p {
  margin-bottom: 0;
}

.editor-edit #riskAssessmentTypeProductRow .attribute-container,
.editor-add #riskAssessmentTypeProductRow .attribute-container {
  padding-left: 0 !important;
}

.risk-assessment-type-change h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.paragraph-margin-top {
  margin-top: 12px;
}
