/* This SCSS file is responsible for all of the CSS on rmp pages.
 */
@import "brand_colors";
@import "globals";

.rmp-criticality-color {
  width: 20px;
  height: 20px;
}

.rmp-criticality-color-blue {
  background: blue;
}

.rmp-criticality-color-grey {
  background: grey;
}

.rmp-criticality-color-green {
  background: green;
}

.rmp-criticality-color-yellow {
  background: yellow;
}

.rmp-criticality-color-orange {
  background: orange;
}

.rmp-criticality-color-red {
  background: red;
}

.rmp-attachments-section-header {
  display: inline-block;
}

.rmp-riskSchema-section-header,
.include-risk-links-section-header {
  display: inline;
  width: 100%;
}

.rmp-attachments-attribute {
  padding-right: 0;
  padding-left: 0;
}

.rmp-copy-btn-flex {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.rmp-tab-content-configured-by-type {
  border-left: 1px solid $brand-mild-grey;
  border-right: 1px solid $brand-mild-grey;
  border-bottom: 1px solid $brand-mild-grey;
}

.rmp-requirement-risk-tab-options {
  padding-top: 10px;

  .form-group {
    margin-bottom: 0 !important;
  }
}

.editor-view .rmp-requirement-risk-tab-options {
  .use-uncertainty,
  .use-detectability,
  .use-capability {
    padding-bottom: 0;

    .view-attribute {
      display: inline-block;
      margin-top: 6px;
      margin-left: 6px;
    }
  }
}

.rmp-requirement-risk-tab {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 10px;
}

#rmpListPage .table-manage {
  min-width: 150px;
}

.rmp-tab-nav-bar-icon {
  margin-left: 5px;
}

.conversion-matrix-widget-details {
  padding-top: 10px;
}

.rmp-risk-table-section-row {
  margin-top: 10px;
}

.rmp-risk-table-options-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.71em;
}

#RMPRiskTableContainer {
  width: 100%;

  table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-collapse: collapse !important;
  }

  #RMPRiskTableContainerColumnHeader,
  #RMPRiskTableContainerRowHeader {
    border: solid 1px $brand-mild-grey;
    text-align: center;
    padding: 10px;
  }

  #RMPRiskTableContainerRowHeader {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .rmp-risk-table-container-row-header-panel {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    border: solid 1px $brand-mild-grey;
    overflow-x: hidden;
  }

  .rmp-risk-table-matrix-container {
    overflow-x: auto;
  }
}

.rmp-risk-table-flex-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rmp-risk-table-container {
  margin-bottom: 10px;
}

.rmp-risk-table-flex-cell-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.rmp-risk-table-show-label {
  margin-right: 10px;
}

.rmp-risk-table-row-header-cell {
  font-weight: bold;
}

.rmp-risk-tables-radio-group-separator {
  height: 18px;
  margin-left: 5px;
  margin-right: 10px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  padding: 0;
}

.rmp-hidden {
  display: none;
}

.change-rmp-container {
  @include rounded-border;

  margin-top: 16px;
  padding-left: 0;
  padding-right: 0;

  .values-header {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .current-rmp {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 400;
    line-height: 20px;
  }

  .more-padding {
    padding-left: 32px;
  }

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #434952;
  }

  .section {
    box-shadow: none;
    padding-bottom: 0;
    margin-right: 12px;
  }

  .section-risk-assessment-type {
    .no-left-padding {
      padding-left: 0;
    }

    .radio-button-container {
      margin-right: 15px;
    }

    p {
      margin-bottom: 0;
    }

    .attribute-container {
      padding-left: 0;
    }
  }

  #invalidRMPRiksAssessment {
    margin-left: 1rem;
    margin-top: -2rem;

    .details {
      margin-left: 30px;
      margin-top: -20px;
    }

    ul {
      padding-inline-start: 2rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  hr {
    margin-bottom: 0;
    margin-top: 0;
  }
}
