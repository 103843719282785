// To see what can be put into here to customize Bootstrap, see: https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss

$brand-primary-color: #1f46a1;
$brand-light-green: #37ad8f;

$primary: $brand-primary-color !default;
$info: $brand-light-green !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-h1: floor(($font-size-base * 1.7)) !default; // ~24px
$font-size-h2: floor(($font-size-base * 1.7)) !default; // ~24px

// Give the popovers more room, if they have it available
$popover-max-width: 400px !default;

$spacer: 20px;
