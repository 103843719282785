/* This SCSS file is responsible for defining the main colors that we use.
 *
 * QbDVision Style guide is here: https://docs.google.com/document/d/1uLxWQyV-gDj8sWZyNXu77s8dje3XebekBevtiTxM9Os/edit#
 */

// Our company colors
$brand-primary-color: #1f46a1;
$brand-highlight-color: #1fbcff;

$brand-dark-blue: #1f1f81;

// Greys, black & white
$brand-black: #000;
$brand-dark-grey: #434952;
$brand-medium-grey: #859099;
$brand-mild-grey: #c0c6cc;
$brand-slight-grey: #dbe1e4;
$brand-light-grey: #f0f4f5;
$brand-lightest-grey: #f5f9fa;
$brand-white: #fff;
$brand-transparent: #000;

// Error and Warning colors
$warning-color: #ffc107;
$error-color: #dd3546;
$success-color: #2dc9a4;
$info-color: $brand-medium-grey;

// Amazon Textract confidence colors
$high-confidence-color: #ededed;
$medium-confidence-color: #fff37e;
$low-confidence-color: #ffc49f;

// Training status colors:
$training-status-pending-color: #fff9e6;
$training-status-overdue-color: #fbeaec;
$training-status-completed-color: #ebf9f7;
$training-status-superseded-color: $brand-light-grey;
$training-status-trained-outside-color: $training-status-completed-color;
$training-status-none-color: $brand-light-grey;

// Hover colors
$hover-color: #999;
